import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";

const TopNavbar = () => {
  return (
    <Navbar color="dark" expand="md" dark>
      <NavbarBrand href="/">
        <img
          className="logo d-inline-block align-top"
          height="30"
          src="/logo_white.png"
          alt=""
        />{" "}
        SuMeet
      </NavbarBrand>
    </Navbar>
  );
};

export default TopNavbar;
