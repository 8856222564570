import React from "react";
import {
  Input,
  Col,
  Form,
  Badge,
  FormGroup,
  FormFeedback,
  Button,
  Jumbotron,
  Container,
} from "reactstrap";
import classnames from "classnames";

class LobbyRoom extends React.Component {
  roomId = null;

  constructor(props) {
    super(props);

    this.state = {
      displayName: "",
      displayNameError: false,
    };

    this.roomId = this.props.match.params.roomId;
    this.handleChangeDisplayName = this.handleChangeDisplayName.bind(this);
    this.startMeet = this.startMeet.bind(this);
  }

  handleChangeDisplayName(event) {
    this.setState({
      displayName: event.target.value,
      displayNameError: false,
    });
  }

  startMeet(event) {
    event.preventDefault();

    if (this.state.displayName.trim() !== "") {
      this.props.history.push(
        `${this.props.match.url}/${this.state.displayName.trim()}`
      );
    } else {
      this.setState({
        displayNameError: true,
      });
    }
  }

  render() {
    return (
      <div className="lobby-room">
        <Jumbotron>
          <Container>
            <p className="lead">
              You will join to Meet:{" "}
              <Badge color="secondary" className="meet-url">
                {this.roomId}
              </Badge>{" "}
              Please, input your Display Name.
            </p>
          </Container>
        </Jumbotron>
        <div className="actions container">
          <Form className="form-lobby" onSubmit={this.startMeet}>
            <FormGroup row>
              <Col xs="12">
                <Input
                  placeholder="Display Name"
                  bsSize="lg"
                  className={classnames("display-name", {
                    "is-invalid": this.state.displayNameError,
                  })}
                  onChange={this.handleChangeDisplayName}
                  value={this.state.displayName}
                />
                {this.state.displayNameError ? (
                  <FormFeedback> Display Name is required </FormFeedback>
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col xs="12">
                <Button color="primary" size="lg" block type="submit">
                  Fire
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default LobbyRoom;
