import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Spinner } from "reactstrap";
import VideoOptions from "./VideoOptions";
import Guest from "./Guest";
import Webrtc from "./Webrtc";
import GuestStatus from "./GuestStatus";
import ModalShareFile from "./ModalShareFile";

class VideoGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enableCamera: true,
      enableMicrophone: true,
    };

    this.shareScreen = this.shareScreen.bind(this);
    this.userCamera = this.userCamera.bind(this);
    this.toggleCamera = this.toggleCamera.bind(this);
    this.toggleMicrophone = this.toggleMicrophone.bind(this);
  }

  userCamera() {
    return this.props.webrtc.switchUserCamera().then(() => {
      this.setState({
        enableCamera: true,
        enableMicrophone: true,
      });
    });
  }

  shareScreen() {
    return this.props.webrtc.switchShareScreen(this.userCamera).then(() => {
      this.setState({
        enableCamera: true,
        enableMicrophone: true,
      });
    });
  }

  toggleCamera() {
    return this.setState({
      enableCamera: this.toggleMediaTrack(Webrtc.TRACK_TYPE_VIDEO),
    });
  }

  toggleMicrophone() {
    return this.setState({
      enableMicrophone: this.toggleMediaTrack(Webrtc.TRACK_TYPE_AUDIO),
    });
  }

  toggleMediaTrack(type) {
    const tracks = this.props.webrtc.localVideoSource.getTracks();
    let result = false;
    tracks.forEach((track) => {
      if (
        track.readyState === Webrtc.TRACK_READY_STATE_LIVE &&
        track.kind === type
      ) {
        track.enabled = !track.enabled;
        result = track.enabled;
      }
    });

    return result;
  }

  getMeetUrl() {
    return window.location.href.replace(`/${this.props.localDisplayName}`, "");
  }

  render() {
    return (
      this.props.webrtc.localVideoSource !== undefined &&
      this.props.webrtc.localVideoSource !== null && (
        <React.Fragment>
          <VideoOptions
            typeVideoSource={this.props.webrtc.typeVideoSource}
            shareScreen={this.shareScreen}
            toggleCamera={this.toggleCamera}
            toggleMicrophone={this.toggleMicrophone}
            enableCamera={this.state.enableCamera}
            enableMicrophone={this.state.enableMicrophone}
            meetUrl={this.getMeetUrl()}
          />
          <div
            id="videos"
            className={classnames("videos", {
              grid: this.props.peerStream.size > 0,
              "guest-head-to-head": this.props.peerStream.size === 1,
              "guest-no-alone":
                this.props.peerStream.size > 1 &&
                this.props.peerStream.size <= 3,
              "guest-group": this.props.peerStream.size > 3,
            })}
          >
            {this.props.peerStream.size > 0 &&
              Array.from(this.props.webrtc.peerConnections, ([key, peer]) =>
                this.props.peerStream.has(key) &&
                this.props.peerStream.get(key).active &&
                peer.pc.connectionState === Webrtc.STATE_CONNECTED ? (
                  <div
                    className={classnames("container-remote-video", {
                      talking: this.props.peerTalking === key,
                    })}
                    key={`container_remote_video_${key}`}
                  >
                    <Guest
                      id={`remote_video_${key}`}
                      key={`remote_video_${key}`}
                      uuid={key}
                      autoPlay={true}
                      muted={false}
                      controls={false}
                      localDisplayName={peer.displayName}
                      videoSource={this.props.peerStream.get(key)}
                      className={peer.pc.connectionState}
                    />
                    <GuestStatus peer={peer}></GuestStatus>
                    <ModalShareFile uuid={key} peer={peer.pc} />
                  </div>
                ) : peer.pc.connectionState === Webrtc.STATE_CONNECTING ||
                  peer.pc.connectionState === Webrtc.STATE_NEW ? (
                  <div key={`loading_video_${key}`} className="video-container">
                    <div className="loading-container">
                      <Spinner
                        style={{ width: "3rem", height: "3rem" }}
                        color="primary"
                      ></Spinner>
                    </div>
                  </div>
                ) : null
              )}
            <Guest
              id="local_video_container"
              key="local_video_container"
              uuid={this.props.localUuid}
              autoPlay={true}
              muted={true}
              controls={false}
              localDisplayName={this.props.localDisplayName}
              videoSource={this.props.webrtc.localVideoSource}
              className="local"
            />
          </div>
        </React.Fragment>
      )
    );
  }
}

VideoGrid.propTypes = {
  localUuid: PropTypes.string.isRequired,
  localDisplayName: PropTypes.string.isRequired,
  peerTalking: PropTypes.string,
  peerStream: PropTypes.object.isRequired,
  webrtc: PropTypes.object.isRequired,
};

export default VideoGrid;
