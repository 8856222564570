import React from "react";

class VideoHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoMp4URL: "video/bg-m1.mp4",
      videoWebmURL: "video/bg-1.webm",
      videoOgvURL: "video/bg-1.ogv",
      poster: "images/bg-1.jpeg",
    };
  }

  render() {
    return (
      <div className="video-bg-header">
        <div className="video-wrapper">
          <div className="video-wrapper-container">
            <video
              loop={false}
              muted={true}
              autoPlay={true}
              poster={this.state.poster}
            >
              <source src={this.state.videoMp4URL} type="video/mp4"></source>
              <source src={this.state.videoWebmURL} type="video/webm"></source>
              <source src={this.state.videoOgvURL} type="video/ogg"></source>
              --&gt;
            </video>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoHeader;
