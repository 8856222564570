import React from "react";
import Room from "components/Room";
import LobbyRoom from "components/Room/LobbyRoom";
import Lobby from "components/Room/Lobby";
import { Switch, Route } from "react-router-dom";

export default () => {
  return (
    <React.Fragment>
      <Switch>
        <Route path="/meet/:roomId/:displayName" component={Room} />
        <Route path="/meet/:roomId" component={LobbyRoom} />
        <Route path="/" component={Lobby} />
      </Switch>
    </React.Fragment>
  );
};
