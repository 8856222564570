import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import DefaultLayout from "layouts/Default";
import PortalPage from "pages/Portal";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <DefaultLayout>
            <PortalPage />
          </DefaultLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
