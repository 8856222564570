import React from "react";

import TopNavbar from "components/TopNavbar";
import Footer from "components/Footer";

function DefaultLayout({ children }) {
  return (
    <div className="d-flex flex-column h-100 sumeet">
      <header className="sumeet-header">
        <TopNavbar />
      </header>
      <main role="main" className="flex-shrink-0">
        <div className="container-fluid">{children}</div>
      </main>
      <Footer />
    </div>
  );
}

export default DefaultLayout;
