import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

class Guest extends React.PureComponent {
  localDisplayName = "";
  uuid = null;

  constructor(props) {
    super(props);
    this.localDisplayName = props.localDisplayName;
    this.uuid = props.uuid;
    this.handleRefVideo = this.handleRefVideo.bind(this);
  }

  handleRefVideo(event) {
    if (event) {
      event.srcObject = this.props.videoSource;
    }

    return;
  }

  render() {
    return (
      <div
        id={this.props.id}
        className={classnames("video-container", this.props.className)}
        key={`video_key_${this.uuid}`}
      >
        <div className="video-wrapper">
          <video
            id={`video_${this.uuid}`}
            ref={this.handleRefVideo}
            autoPlay={this.props.autoPlay}
            muted={this.props.muted}
            controls={this.props.controls}
          ></video>
        </div>
        <div className="video-label">{this.localDisplayName}</div>
      </div>
    );
  }
}

Guest.propTypes = {
  videoSource: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  muted: PropTypes.bool.isRequired,
  controls: PropTypes.bool.isRequired,
  autoPlay: PropTypes.bool.isRequired,
};

export default Guest;
