class Webrtc {
  static STATE_CONNECTED = "connected";
  static STATE_CONNECTING = "connecting";
  static STATE_NEW = "new";
  static STATE_FAILED = "failed";
  static STATE_CLOSED = "closed";
  static STATE_DISCONNECTED = "disconnected";
  static TYPE_STREAM_SOURCE_USER_CAMERA = "user_camera";
  static TYPE_STREAM_SOURCE_SCREEN = "screen_display";
  static TALKING_THRESHOLD = 0.011;
  static QUIET_THRESHOLD = 0.00022;
  static TRACK = "track";
  static TRACK_TYPE_AUDIO = "audio";
  static TRACK_TYPE_VIDEO = "video";

  static TRACK_READY_STATE_LIVE = "live";
  static TRACK_READY_STATE_ENDED = "ended";

  #peerConnections = null;
  #localVideoSource = null;
  #typeVideoSource = null;
  #peerConnectionConfig = {
    iceServers: [
      { urls: "stun:stun.l.google.com:19302" },
      { urls: "stun:stun.stunprotocol.org:3478" },
    ],
  };
  #videoConstraints = {
    video: {
      width: { max: 640 },
      height: { max: 480 },
      frameRate: { ideal: 20, max: 30 },
      aspectRatio: 1.777777778,
      facingMode: "user",
    },
    audio: {
      noiseSuppression: true,
      // echoCancellation: true,
      sampleRate: 44100,
    },
  };

  constructor(
    peerConnections,
    localVideoSource,
    typeVideoSource,
    peerConnectionConfig = null,
    videoConstraints = null
  ) {
    this.#peerConnections = peerConnections;
    this.#localVideoSource = localVideoSource;
    this.#typeVideoSource = typeVideoSource;

    if (peerConnectionConfig !== null) {
      this.#peerConnectionConfig = peerConnectionConfig;
    }

    if (videoConstraints !== null) {
      this.#videoConstraints = videoConstraints;
    }
  }

  get supportUserMedia() {
    return (
      navigator.mediaDevices.getUserMedia !== undefined &&
      navigator.mediaDevices.getUserMedia !== null
    );
  }

  get supportDisplayMedia() {
    return (
      navigator.mediaDevices.getDisplayMedia !== undefined &&
      navigator.mediaDevices.getDisplayMedia !== null
    );
  }

  mediaDevicesByTypeSource(type) {
    switch (type) {
      case Webrtc.TYPE_STREAM_SOURCE_USER_CAMERA:
        if (this.supportUserMedia) {
          return navigator.mediaDevices.getUserMedia(this.videoConstraints);
        } else {
          alert("Your browser does not support getUserMedia API");
        }

        break;
      case Webrtc.TYPE_STREAM_SOURCE_SCREEN:
        if (this.supportDisplayMedia) {
          return navigator.mediaDevices.getDisplayMedia(this.videoConstraints);
        } else {
          alert("Your browser does not support getDisplayMedia API");
        }
        break;

      default:
        break;
    }

    return false;
  }

  async updateStream(stream, callbackEndTrack = null) {
    const tracks = this.#localVideoSource.getTracks();

    tracks.forEach((track) => {
      if (track.readyState === Webrtc.TRACK_READY_STATE_LIVE) {
        track.stop();
      }

      this.#localVideoSource.removeTrack(track);
    });

    let newTracks = [];

    stream.getTracks().forEach((trackStream) => {
      if (
        this.#typeVideoSource !== Webrtc.TYPE_STREAM_SOURCE_SCREEN ||
        trackStream.kind !== Webrtc.TRACK_TYPE_AUDIO
      ) {
        newTracks.push(trackStream);

        if (callbackEndTrack !== null) {
          trackStream.onended = callbackEndTrack;
        }

        this.#localVideoSource.addTrack(trackStream);
      }
    });

    if (this.#typeVideoSource === Webrtc.TYPE_STREAM_SOURCE_SCREEN) {
      const cameraMediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      const audioTracks = cameraMediaStream.getTracks();

      audioTracks.forEach((audioTrack) => {
        newTracks.push(audioTrack);
        this.#localVideoSource.addTrack(audioTrack);
      });
    }

    this.#peerConnections.forEach((peer) => {
      const sendersTrack = new Map();

      peer.pc.getSenders().forEach((sender) => {
        sendersTrack.set(sender.track.kind, sender);
      });

      newTracks.forEach((track) => {
        if (
          track.readyState === Webrtc.TRACK_READY_STATE_LIVE &&
          sendersTrack.has(track.kind)
        ) {
          sendersTrack.get(track.kind).replaceTrack(track);
        }
      });
    });

    return true;
  }

  switchShareScreen(callbackEndTrack = () => {}) {
    const switchUserCamera = callbackEndTrack;

    return this.mediaDevicesByTypeSource(Webrtc.TYPE_STREAM_SOURCE_SCREEN)
      .then((stream) => {
        this.typeVideoSource = Webrtc.TYPE_STREAM_SOURCE_SCREEN;
        this.updateStream(stream, switchUserCamera);
      })
      .catch((error) => console.error(error));
  }

  switchUserCamera() {
    return this.mediaDevicesByTypeSource(Webrtc.TYPE_STREAM_SOURCE_USER_CAMERA)
      .then((stream) => {
        this.typeVideoSource = Webrtc.TYPE_STREAM_SOURCE_USER_CAMERA;
        this.updateStream(stream);
      })
      .catch((error) => console.error(error));
  }

  get peerConnectionConfig() {
    return this.#peerConnectionConfig;
  }

  get videoConstraints() {
    return this.#videoConstraints;
  }

  get localVideoSource() {
    return this.#localVideoSource;
  }

  get peerConnections() {
    return this.#peerConnections;
  }

  get typeVideoSource() {
    return this.#typeVideoSource;
  }

  /**
   * @param {{ iceServers: { urls: string; }[]; }} config
   */
  set peerConnectionConfig(config) {
    this.#peerConnectionConfig = config;
    return this;
  }

  /**
   * @param {{ video: { width: { max: number; }; height: { max: number; }; frameRate: { ideal: number; max: number; }; aspectRatio: number; facingMode: string; }; audio: boolean; }} constraints
   */
  set videoConstraints(constraints) {
    this.#videoConstraints = constraints;
    return this;
  }

  set localVideoSource(localVideoSource) {
    this.#localVideoSource = localVideoSource;
    return this;
  }

  set peerConnections(peerConnections) {
    this.#peerConnections = peerConnections;
    return this;
  }

  set typeVideoSource(typeVideoSource) {
    this.#typeVideoSource = typeVideoSource;
    return this;
  }

  static get createUUID() {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };

    return (
      s4() +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      "-" +
      s4() +
      s4() +
      s4()
    );
  }
}

export default Webrtc;
