import React from "react";
import { Button, Progress } from "reactstrap";
import Channel from "lib/webrtc/channel";
import ServerConnection from "./ServerConnection";
import Webrtc from "./Webrtc";
import VideoGrid from "./VideoGrid";
import { getCompleteFile } from "./helpers/helpers";

class Room extends React.Component {
  localUuid = null;
  roomId = null;
  localDisplayName = "";
  serverConnection = null;
  webrtc = null;
  watcherConnections = null;
  watcherReconnect = null;

  constructor(props) {
    super(props);

    this.state = {
      peerConnections: new Map(),
      peerStream: new Map(),
      localVideoSource: null,
      peerTalking: null,
      progressTransferFile: 0,
    };

    this.webrtc = new Webrtc(
      this.state.peerConnections,
      this.state.localVideoSource,
      Webrtc.TYPE_STREAM_SOURCE_USER_CAMERA
    );

    this.localUuid = Webrtc.createUUID;
    this.roomId = this.props.match.params.roomId || Webrtc.createUUID;
    this.localDisplayName =
      this.props.match.params.displayName || this.localUuid;

    this.startServerConnection = this.startServerConnection.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.gotMessageFromServer = this.gotMessageFromServer.bind(this);
    this.gotIceCandidate = this.gotIceCandidate.bind(this);
    this.disconnectRoom = this.disconnectRoom.bind(this);
    this.closeRoom = this.closeRoom.bind(this);

    this.props.history.replace(`/meet/${this.roomId}/${this.localDisplayName}`);
  }

  componentDidMount() {
    this.webrtc
      .mediaDevicesByTypeSource(this.webrtc.typeVideoSource)
      .then((stream) => {
        this.setState({
          localVideoSource: stream,
        });
      })
      .catch(this.errorHandler)
      .then(this.startServerConnection)
      .catch(this.errorHandler);

    if (process.env.REACT_APP_FEATURE_DETECT_TALKING === "true") {
      this.watcherConnections = window.setInterval(() => {
        this.getConnectionStats();
      }, 500);
    }

    this.watcherReconnect = window.setInterval(() => {
      this.reconnect();
    }, 10000);
  }

  componentDidUpdate() {
    // Update the webrtc object with the peer, local and type connection values.
    this.updateWebrtcValues();
  }

  componentWillUnmount() {
    this.disconnectRoom();
  }

  reconnect() {
    if (this.state.peerConnections.size === 0) {
      console.log("Reconnecting ......");

      this.serverConnection.send({
        displayName: this.localDisplayName,
        uuid: this.localUuid,
        roomId: this.roomId,
        dest: "all",
      });
    }
  }

  closeRoom() {
    this.disconnectRoom();
    this.props.history.push("/");
  }

  disconnectRoom() {
    clearInterval(this.watcherConnections);
    clearInterval(this.watcherReconnect);

    if (this.state.localVideoSource) {
      const tracks = this.state.localVideoSource.getTracks();
      let trackClosed = false;

      tracks.forEach((track) => {
        if (track.readyState === Webrtc.TRACK_READY_STATE_LIVE) {
          track.stop();
          trackClosed = true;
        }
      });

      if (trackClosed) {
        this.serverConnection.send({
          displayName: this.localDisplayName,
          uuid: this.localUuid,
          roomId: this.roomId,
          dest: "all",
          peerClossed: true,
        });
      }

      if (this.state.peerConnections && this.state.peerConnections.size > 0) {
        this.state.peerConnections.forEach((peer) => {
          peer.eventListeners.forEach((eventListener) => {
            peer.pc.removeEventListener(
              eventListener.event,
              eventListener.callback
            );
          });
          peer.pc.close();
        });
      }
    }

    if (this.serverConnection !== null && this.serverConnection.isOpen) {
      this.serverConnection.close();
    }

    return;
  }

  errorHandler(error) {
    console.log(error);
  }

  updateWebrtcValues() {
    this.webrtc.peerConnections = this.state.peerConnections;
    this.webrtc.localVideoSource = this.state.localVideoSource;

    return;
  }

  gotIceCandidate(event, peerUuid) {
    if (event.candidate != null) {
      this.serverConnection.send({
        ice: event.candidate,
        uuid: this.localUuid,
        roomId: this.roomId,
        dest: peerUuid,
      });
    }
  }

  checkPeerDisconnect(event, peerUuid) {
    if (this.state.peerConnections.has(peerUuid)) {
      const peerConnection = this.state.peerConnections.get(peerUuid).pc;
      const iceConnectionState = peerConnection.iceConnectionState;

      console.log(`connection with peer ${peerUuid} ${iceConnectionState}`);

      if (iceConnectionState === Webrtc.STATE_FAILED) {
        peerConnection.restartIce();
      }

      if (
        peerConnection.connectionState !== Webrtc.STATE_CONNECTED &&
        peerConnection.connectionState !== Webrtc.STATE_CONNECTING &&
        iceConnectionState === Webrtc.STATE_CONNECTED
      ) {
        this.setState((state) => {
          let newPeerConnections = state.peerConnections;
          let peerConnected =
            newPeerConnections.has(peerUuid) &&
            newPeerConnections.get(peerUuid);

          if (peerConnected) {
            if (event.currentTarget.connectionState !== undefined) {
              peerConnected.pc = event.currentTarget;
            } else {
              peerConnected.pc.connectionState = Webrtc.STATE_CONNECTED;
            }
            newPeerConnections.set(peerUuid, peerConnected);

            return {
              peerConnections: newPeerConnections,
            };
          }

          return state;
        });
      }

      if (
        iceConnectionState === Webrtc.STATE_CLOSED ||
        iceConnectionState === Webrtc.STATE_DISCONNECTED
      ) {
        this.setState((state) => {
          let newPeerConnections = state.peerConnections;
          let newPeerStream = state.peerStream;
          newPeerConnections.delete(peerUuid);
          newPeerStream.delete(peerUuid);

          return {
            peerConnections: newPeerConnections,
            peerStream: newPeerStream,
          };
        });
      }
    } else {
      console.log(`Peer ${peerUuid} does not exist`);
    }
  }

  async gotRemoteStream(event, peerUuid) {
    console.log(`got remote stream, peer ${peerUuid}`);

    this.setState((state) => {
      let newPeerStream = state.peerStream;

      if (newPeerStream.has(peerUuid)) {
        if (
          newPeerStream.get(peerUuid).id === event.streams[0].id &&
          newPeerStream.get(peerUuid).active
        ) {
          return state;
        }
      }

      newPeerStream.delete(peerUuid);
      newPeerStream.set(peerUuid, event.streams[0]);

      return {
        peerStream: newPeerStream,
      };
    });
  }

  async createdDescription(description, peerUuid) {
    console.log(`got description, peer ${peerUuid}`);
    if (this.state.peerConnections.has(peerUuid)) {
      this.state.peerConnections
        .get(peerUuid)
        .pc.setLocalDescription(description)
        .then(() => {
          this.serverConnection.send({
            sdp: this.state.peerConnections.get(peerUuid).pc.localDescription,
            uuid: this.localUuid,
            roomId: this.roomId,
            dest: peerUuid,
          });
        })
        .catch(this.errorHandler);
    }
  }

  async setUpPeer(peerUuid, displayName, initCall = false) {
    const peerConnection = new RTCPeerConnection(
      this.webrtc.peerConnectionConfig
    );

    const onIceCandidateCallback = (event) =>
      this.gotIceCandidate(event, peerUuid);
    const onTrackCallback = (event) => this.gotRemoteStream(event, peerUuid);
    const onConnectionStateChangeCallback = (event) => {
      if (peerConnection.connectionState === Webrtc.STATE_FAILED) {
        peerConnection.restartIce();
      }

      if (
        peerConnection.connectionState === Webrtc.STATE_CONNECTED ||
        peerConnection.connectionState === Webrtc.STATE_CONNECTING
      ) {
        this.setState((state) => {
          const newPeerConnections = state.peerConnections;
          const peerConnected =
            newPeerConnections.has(peerUuid) &&
            newPeerConnections.get(peerUuid);

          if (peerConnected) {
            peerConnected.pc = event.currentTarget;
            newPeerConnections.set(peerUuid, peerConnected);

            return {
              peerConnections: newPeerConnections,
            };
          }

          return state;
        });
      }
    };

    const onIceConnectionStateChangeCallback = (event) =>
      this.checkPeerDisconnect(event, peerUuid);

    const createdDescription = (description) =>
      this.createdDescription(description, peerUuid);

    const onNegotiationneededCallback = () => {
      if (initCall) {
        peerConnection
          .createOffer()
          .then(createdDescription)
          .catch(this.errorHandler);
      } else if (peerConnection.localDescription !== null) {
        peerConnection
          .createOffer()
          .then(createdDescription)
          .catch(this.errorHandler);
      }
    };

    const onDataChannelCallback = (event) => {
      const { channel } = event;

      let receivedBuffer = [];
      let totalBytesFileBuffer = 0;
      let totalBytesArrayBuffers = 0;

      channel.onmessage = (event) => {
        const { data } = event;

        try {
          if (data.byteLength) {
            receivedBuffer.push(data);

            totalBytesArrayBuffers += data.byteLength;

            if (totalBytesFileBuffer > 0) {
              this.setState({
                progressTransferFile:
                  (totalBytesArrayBuffers * 100) / totalBytesFileBuffer,
              });
            }
          } else if (data === Channel.LAST_DATA_OF_FILE) {
            getCompleteFile(
              receivedBuffer,
              totalBytesArrayBuffers,
              channel.label
            );
            channel.close();

            receivedBuffer = [];
            totalBytesFileBuffer = 0;
            totalBytesArrayBuffers = 0;
          } else {
            const initMessage = JSON.parse(data);
            totalBytesFileBuffer = initMessage.totalByte || 0;
          }
        } catch (err) {
          receivedBuffer = [];
          totalBytesFileBuffer = 0;
          totalBytesArrayBuffers = 0;
        }
      };
    };

    peerConnection.addEventListener("icecandidate", onIceCandidateCallback);
    peerConnection.addEventListener(
      "iceconnectionstatechange",
      onIceConnectionStateChangeCallback
    );
    peerConnection.addEventListener("track", onTrackCallback);
    peerConnection.addEventListener(
      "connectionstatechange",
      onConnectionStateChangeCallback
    );

    peerConnection.addEventListener(
      "negotiationneeded",
      onNegotiationneededCallback
    );

    peerConnection.addEventListener("datachannel", onDataChannelCallback);

    if (this.state.localVideoSource !== null) {
      for (const track of this.state.localVideoSource.getTracks()) {
        peerConnection.addTrack(track, this.state.localVideoSource);
      }
    }

    this.setState((state) => {
      let newPeerConnections = state.peerConnections;

      newPeerConnections.set(peerUuid, {
        displayName: displayName,
        pc: peerConnection,
        eventListeners: [
          {
            event: "icecandidate",
            callback: onIceCandidateCallback,
          },
          {
            event: "iceconnectionstatechange",
            callback: onIceConnectionStateChangeCallback,
          },
          {
            event: "track",
            callback: onTrackCallback,
          },
          {
            event: "connectionstatechange",
            callback: onConnectionStateChangeCallback,
          },
          {
            event: "negotiationneeded",
            callback: onNegotiationneededCallback,
          },
          {
            event: "ondatachannel",
            callback: onDataChannelCallback,
          },
        ],
      });

      return {
        peerConnections: newPeerConnections,
      };
    });
  }

  async gotMessageFromServer(message) {
    const signal = JSON.parse(message.data);
    const peerUuid = signal.uuid;

    if (peerUuid === this.localUuid && signal.iceServers) {
      this.webrtc.peerConnectionConfig = { iceServers: signal.iceServers };
      return;
    } else if (signal.dest !== this.localUuid && signal.dest !== "all") {
      return;
    }

    if (signal.peerClossed === true) {
      if (this.state.peerConnections.has(peerUuid)) {
        if (
          this.state.peerConnections.get(peerUuid).pc.connectionState !==
          Webrtc.STATE_CLOSED
        ) {
          this.state.peerConnections.get(peerUuid).pc.close();

          this.setState((state) => {
            let newPeerConnections = state.peerConnections;
            let newPeerStream = state.peerStream;
            newPeerConnections.delete(peerUuid);
            newPeerStream.delete(peerUuid);

            return {
              peerConnections: newPeerConnections,
              peerStream: newPeerStream,
            };
          });
        }
      }
    } else if (signal.displayName && signal.dest === "all") {
      // set up peer connection object for a newcomer peer
      await this.setUpPeer(peerUuid, signal.displayName);
      this.serverConnection.send({
        displayName: this.localDisplayName,
        uuid: this.localUuid,
        roomId: this.roomId,
        dest: peerUuid,
      });
    } else if (signal.displayName && signal.dest === this.localUuid) {
      // initiate call if we are the newcomer peer
      await this.setUpPeer(peerUuid, signal.displayName, true);
    } else if (signal.sdp) {
      if (this.state.peerConnections.get(peerUuid)) {
        const createdDescription = (description) =>
          this.createdDescription(description, peerUuid);

        const setRemoteDescription = () => {
          if (signal.sdp.type === "offer") {
            this.state.peerConnections
              .get(peerUuid)
              .pc.createAnswer()
              .then(createdDescription)
              .catch(this.errorHandler);
          }

          return;
        };

        this.state.peerConnections
          .get(peerUuid)
          .pc.setRemoteDescription(new RTCSessionDescription(signal.sdp))
          .then(setRemoteDescription)
          .catch(this.errorHandler);
      }
    } else if (signal.ice) {
      if (this.state.peerConnections.get(peerUuid)) {
        this.state.peerConnections
          .get(peerUuid)
          .pc.addIceCandidate(new RTCIceCandidate(signal.ice))
          .catch(this.errorHandler);
      }
    }

    return true;
  }

  async startServerConnection() {
    this.serverConnection = new ServerConnection();

    const onOpenCallback = () => {
      this.serverConnection.send({
        displayName: this.localDisplayName,
        uuid: this.localUuid,
        roomId: this.roomId,
        dest: "all",
        join: true,
      });
    };

    const onCloseCallback = () => {
      console.log(
        `Close conection Room id: ${this.roomId} User id: ${this.localUuid}`
      );
    };

    this.serverConnection.setOnMessageCallback(this.gotMessageFromServer);
    this.serverConnection.setOnOpenCallback(onOpenCallback);
    this.serverConnection.setOnCloseCallback(onCloseCallback);

    return;
  }

  getConnectionStats() {
    if (
      this.state &&
      this.state.peerConnections &&
      this.state.peerConnections.size > 0
    ) {
      const performancePeerTalking = (peerKey, stats) => {
        let peerTalkingKey = null;
        let peerAudioLevelMax = 0;

        stats.forEach((report) => {
          if (
            report.type === Webrtc.TRACK &&
            report.kind === Webrtc.TRACK_TYPE_AUDIO &&
            report.remoteSource === !undefined &&
            report.remoteSource === true
          ) {
            if (
              report.audioLevel &&
              parseFloat(report.audioLevel) > Webrtc.TALKING_THRESHOLD &&
              parseFloat(peerAudioLevelMax) < parseFloat(report.audioLevel)
            ) {
              peerTalkingKey = peerKey;
              peerAudioLevelMax = parseFloat(report.audioLevel);
            }
          }
        });

        return {
          peerTalkingKey,
          peerAudioLevelMax,
        };
      };

      const promiseChainStats = Array.from(
        this.state.peerConnections,
        ([key, peer]) => {
          return peer.pc
            .getStats()
            .then((stats) => performancePeerTalking(key, stats));
        }
      );

      promiseChainStats
        .reduce((promiseChain, currentTask) => {
          return promiseChain.then((chainResults) =>
            currentTask.then((currentResult) => [
              ...chainResults,
              currentResult,
            ])
          );
        }, Promise.resolve([]))
        .then((arrayOfResults) => {
          let peerTalkingKey = null;
          let peerAudioLevelMax = 0;

          if (arrayOfResults.length > 0) {
            arrayOfResults.forEach((result) => {
              if (peerAudioLevelMax < result.peerAudioLevelMax) {
                peerAudioLevelMax = result.peerAudioLevelMax;
                peerTalkingKey = result.peerTalkingKey;
              }
            });

            this.setState((state) => {
              if (state.peerTalking !== peerTalkingKey) {
                return {
                  ...state,
                  peerTalking: peerTalkingKey,
                };
              }
              return;
            });
          }
        });

      return true;
    }
  }

  render() {
    if (this.webrtc.localVideoSource === null) {
      this.webrtc.localVideoSource = this.state.localVideoSource;
    }

    return (
      <div className="sumeet-room">
        <VideoGrid
          webrtc={this.webrtc}
          localUuid={this.localUuid}
          localDisplayName={this.localDisplayName}
          peerTalking={this.state.peerTalking}
          peerStream={this.state.peerStream}
        />
        <Button className="close-room" color="primary" onClick={this.closeRoom}>
          <i className="fas fa-sign-out-alt"></i>
        </Button>
        {this.state.progressTransferFile > 0 && (
          <div className="progress-bar-notifications">
            <div className="text-center">
              Downloading file {parseInt(this.state.progressTransferFile)}%
            </div>
            <Progress value={parseInt(this.state.progressTransferFile)} />
          </div>
        )}
      </div>
    );
  }
}

export default Room;
