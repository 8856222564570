import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import classnames from "classnames";
import Webrtc from "./Webrtc";

class VideoOptions extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      copyClipboard: false,
    };

    this.copyMeetUrl = this.copyMeetUrl.bind(this);
  }

  setOptionsButton(typeVideoSource) {
    switch (typeVideoSource) {
      case Webrtc.TYPE_STREAM_SOURCE_USER_CAMERA:
        return "primary";

      case Webrtc.TYPE_STREAM_SOURCE_SCREEN:
        return "danger";

      default:
        break;
    }

    return "default";
  }

  copyMeetUrl() {
    navigator.clipboard.writeText(this.props.meetUrl);
    this.setState({
      copyClipboard: true,
    });

    setTimeout(() => {
      this.setState({
        copyClipboard: false,
      });
    }, 2000);
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={classnames("video-options-notifications", {
            enabled: this.state.copyClipboard,
          })}
        >
          URL has been copied to clipboard
        </div>
        <div className="video-options">
          <Button
            color={this.setOptionsButton(this.props.typeVideoSource)}
            onClick={this.props.shareScreen}
            disabled={
              this.props.typeVideoSource === Webrtc.TYPE_STREAM_SOURCE_SCREEN
            }
          >
            <i className="fas fa-share-square"></i>
          </Button>
          <Button
            color={classnames({
              primary: this.props.enableCamera,
              secondary: !this.props.enableCamera,
            })}
            onClick={this.props.toggleCamera}
          >
            <i className="fas fa-camera-retro"></i>
          </Button>
          <Button
            color={classnames({
              primary: this.props.enableMicrophone,
              secondary: !this.props.enableMicrophone,
            })}
            onClick={this.props.toggleMicrophone}
          >
            <i className="fas fa-microphone-alt"></i>
          </Button>
          <Button
            color={classnames({
              primary: !this.state.copyClipboard,
              secondary: this.state.copyClipboard,
            })}
            onClick={this.copyMeetUrl}
            data={this.props.meetUrl}
            disabled={this.state.copyClipboard}
          >
            <i className="fas fa-share-alt"></i>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

VideoOptions.propTypes = {
  typeVideoSource: PropTypes.oneOf([
    Webrtc.TYPE_STREAM_SOURCE_USER_CAMERA,
    Webrtc.TYPE_STREAM_SOURCE_SCREEN,
  ]).isRequired,
  toggleCamera: PropTypes.func.isRequired,
  toggleMicrophone: PropTypes.func.isRequired,
  shareScreen: PropTypes.func.isRequired,
  meetUrl: PropTypes.string.isRequired,
  enableMicrophone: PropTypes.bool.isRequired,
  enableCamera: PropTypes.bool.isRequired,
};

export default VideoOptions;
