class ServerConnection {
  #WS_PORT = process.env.REACT_APP_WS_PORT || 8443;
  #WS_HOST = process.env.REACT_APP_WS_HOST || "localhost";
  connection = null;

  constructor() {
    this.connection = new WebSocket(
      "wss://" + this.#WS_HOST + ":" + this.#WS_PORT
    );
  }

  setOnMessageCallback(callback) {
    this.connection.addEventListener("message", callback);
  }

  setOnOpenCallback(callback) {
    this.connection.addEventListener("open", callback);
  }

  setOnCloseCallback(callback) {
    this.connection.addEventListener("close", callback);
  }

  send(data) {
    if (this.isOpen) {
      this.connection.send(JSON.stringify(data));
    }
  }

  get getConnection() {
    return this.connection;
  }

  get isOpen() {
    return this.connection.readyState === WebSocket.OPEN;
  }

  close() {
    if (this.isOpen) {
      this.connection.close();
    }
  }
}

export default ServerConnection;
