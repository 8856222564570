import React from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Channel from "lib/webrtc/channel";

class ModalShareFile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      fileToShare: null,
      isTransferingFile: false,
    };

    this.changeShareFile = this.changeShareFile.bind(this);
    this.shareFile = this.shareFile.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState((state) => ({
      modal: !state.modal,
    }));
  }

  shareFile(fileToShare) {
    return () => {
      if (fileToShare) {
        const channelLabel = fileToShare.name;
        const channelByPeer = new Channel(this.props.peer, channelLabel);

        this.setState({
          isTransferingFile: true,
          modal: false,
        });

        channelByPeer.transferFile(fileToShare);
        this.setState({
          isTransferingFile: false,
        });

        // channelByPeer.channel.onclose = () => {
        //   this.setState({
        //     modal: false,
        //   });
        // };
      }
    };
  }

  changeShareFile(event) {
    const fileToShare = event.target.files[0];

    this.setState({
      fileToShare: fileToShare,
    });
  }

  render() {
    return (
      <div className="guest-status">
        <Button
          className="guest-share-file"
          color="primary"
          onClick={this.toggleModal}
        >
          {!this.state.isTransferingFile ? (
            <i className="fas fa-file-upload"></i>
          ) : (
            <i class="fas fa-spinner"></i>
          )}
        </Button>
        <Modal isOpen={this.state.modal}>
          <ModalBody>
            <FormGroup>
              <Label for={`share_file_${this.props.uuid}`}>Upload File</Label>
              <Input
                type="file"
                name="file"
                id={`share_file_${this.props.uuid}`}
                onChange={this.changeShareFile}
              />
              <FormText color="muted">Share a file max 1TB.</FormText>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.shareFile(this.state.fileToShare)}
            >
              Share!
            </Button>{" "}
            <Button color="secondary" onClick={this.toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ModalShareFile.propTypes = {
  uuid: PropTypes.string.isRequired,
  peer: PropTypes.shape({}).isRequired,
};

export default ModalShareFile;
