import Webrtc from "./Webrtc";
import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

class GuestStatus extends React.PureComponent {
  watcherPeer = null;
  audioLevelEnvironment = 0;

  constructor(props) {
    super(props);
    this.state = {
      enabledAudio: true,
    };
    this.performanceStats = this.performanceStats.bind(this);
  }

  componentDidMount() {
    this.watcherPeer = window.setInterval(() => {
      // this.performanceStats();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.watcherPeer);
  }

  updateAudioStatus(status) {
    this.setState((state) => {
      if (state.enabledAudio !== status) {
        return {
          enabledAudio: status,
        };
      }
      return;
    });
  }

  performanceStats() {
    return this.props.peer.pc.getStats().then((stats) => {
      let respond = true;

      stats.forEach((report) => {
        if (
          report.type === Webrtc.TRACK &&
          report.kind === Webrtc.TRACK_TYPE_AUDIO &&
          report.remoteSource === !undefined &&
          report.remoteSource === true
        ) {
          this.audioLevelEnvironment =
            report.totalAudioEnergy * report.audioLevel;

          if (this.audioLevelEnvironment <= 0.00009) {
            respond = false;
            this.updateAudioStatus(respond);
          }
        }
      });

      if (respond === true) {
        this.updateAudioStatus(respond);
      }

      return;
    });
  }

  render() {
    return (
      this.state.enabledAudio === false && (
        <div className="guest-status">
          <Button className="guest-quiet" color="danger" disabled={true}>
            <i className="fas fa-microphone-slash"></i>
          </Button>
        </div>
      )
    );
  }
}

GuestStatus.propTypes = {
  peer: PropTypes.object.isRequired,
};

export default GuestStatus;
